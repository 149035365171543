import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Background from '../images/background.jpg'

export default () => (
  <Layout>
    <section className="text-section">
      <h1>Rinascimento informativo a Desio</h1>
      <p><strong><i>Hack Desio</i></strong> è uno spazio di comunicazione civica e politica.</p>
      <p>Per maggiori informazioni sul progetto guarda la pagina <Link to="/about" className="link--rebecca">INFO</Link>.</p>
      <p>L'elenco degli articoli è disponibile nella pagina <Link className="link--rebecca" to="/articles">ARTICOLI</Link>.</p>
    </section>

    <img className="background" src={Background} alt="graffiti with flying swallows" />
  </Layout>
)

import React from 'react'
import Button from './button'

import './footer.css'

export default () => (
  <footer className="Footer">
    <Button url="https://www.facebook.com/hackdesio">
      <i className="fab fa-facebook"></i> <i className="fas fa-newspaper"></i>
      <span>Pagina</span>
      <i className="fas fa-external-link-alt"></i>
    </Button>
    <Button url="https://www.facebook.com/groups/2078889439025011">
      <i className="fab fa-facebook"></i> <i className="fas fa-users"></i>
      <span>Gruppo</span>
      <i className="fas fa-external-link-alt"></i>
    </Button>
    <Button url="mailto:hack@desio.org">
      <span>hack@desio.org</span>
    </Button>
  </footer>
)
  

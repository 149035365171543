import React from 'react'
import { Link } from 'gatsby'
import './header.css'

export default ({ siteTitle }) => (
  <header className="Header">
    <h1>
      <Link to="/" className="link link--rebecca">
        {siteTitle}
      </Link>
    </h1>
    <div className="pages-link">
      <Link to="/">Home</Link>
      <Link to="/about">Info</Link>
      <Link to="/articles">Articoli</Link>
      <Link to="/rules">Codice di Condotta</Link>
    </div>
  </header>
)

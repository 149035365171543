import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Main from './main'
import Footer from './footer'

import './fonts.css'
import './base.css'
import './layout.css'

import Logo from '../images/hack-desio.png'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Hack Desio: Rinascimento informativo a Desio.' },
            { name: 'keywords', content: 'Desio, Civic Hacking, Opendata, Opensource, Politica' },
          ]}
          bodyAttributes={{ class: 'BodyContainer' }}
        >
          <html lang="it" />
          <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" crossorigin="anonymous" />
          <meta property="og:title" content="Hack Desio_" />
          <meta property="og:description" content="Desio, Civic Hacking, Opendata, Opensource, Politica" />
          <meta property="og:image" content={`${data.site.siteMetadata.siteUrl}${Logo}`} />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Main className="Main">
          {children}
        </Main>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
